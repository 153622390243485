.alert-modal .title {
  min-height: 8px;
  padding-right: 48px;
}

.alert-modal .close-button {
  font-size: 14px;
  -webkit-appearance: none;
  color: #d52b1e;
  background-color: #fff;
  border: 1px solid #d52b1e;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 0 0 10px;
  cursor: pointer;
  font-family: 'DINPro' !important;
  overflow: hidden;
  white-space: nowrap;
}
